(function($) {
    $(document).ready(function () {
        var hamburger = $('#hamburger');
        var navMobileWrap = $('#nav-mobile-wrap');
        var navMobileClose = navMobileWrap.find('.js-nav-mobile-close');

        hamburger.click(function () {
            // $(this).toggleClass('is-active');
            navMobileWrap.addClass('in');
        });

        navMobileClose.click(function () {
            navMobileWrap.removeClass('in');
        });
    });
}(jQuery));