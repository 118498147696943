(function($) {
    $(document).ready(function () {
        var searchMuellSub = $('#search-muell-sub');
        var resultList = $('#result-list');
        var wertstoffList = $('#wertstoff-list');
        var resultDefault = resultList.find('.result.default');
        var resultEmpty = resultList.find('.result.empty');
        var sw = $('#sw').data('sw');
        var muellIcons = searchMuellSub.find('.icon');
        var muellIconsCategories = [];
        var muellIconsMax = 8;

        if (searchMuellSub.length > 0) {
            muellIcons.each(function () {
                muellIconsCategories.push( $(this).attr('data-category') );
            });

            showRandomMuellIcons();

            var searchField = searchMuellSub.find('.search-field');

            // if there are URL params, do it
            search_by_params();

            /**
             * search on user input
             */
            searchField.on('input', function () {
                if (searchField.val().length >= 2) {
                    resultDefault.removeClass('in');
                    resultEmpty.removeClass('in');
                    ajaxSearch(false);
                } else {
                    wertstoffList.empty();
                    resultEmpty.removeClass('in');
                    resultDefault.addClass('in');
                    showRandomMuellIcons();
                }
            });

            /**
             * check custom URL params and dowhattodo
             */
            function search_by_params() {
                var url_string = window.location.href;
                var url = new URL(url_string);
                var i = url.searchParams.get('i');

                if (i && i.length > 0) {
                    searchField.val(i);
                    resultDefault.removeClass('in');
                    resultEmpty.removeClass('in');
                    ajaxSearch(true);
                }
            }

            /**
             * do AJAX search
             */
            function ajaxSearch(callWsInfo) {
                var resultMarkup;
                resultList.css('opacity', 0.5);

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'search_wertstoffe_sub',
                        search_input: searchField.val(),
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        resultMarkup = data.substring(0, data.length - 1);
                        if (resultMarkup.length > 0) {
                            // put results in list
                            wertstoffList.html(resultMarkup);
                            doMuellIcons();
                        } else {
                            wertstoffList.empty();
                            resultEmpty.removeClass('in');
                            resultEmpty.addClass('in');
                            removeMuellIcons();
                        }

                        resultList.css('opacity', 1);

                        // if SuchWort is set and info shall be opened and there is at least one result
                        if (sw && sw.toString().length > 0 && callWsInfo && $('.wertstoff').length > 0) {
                            var wsFittingToSw = $('.wertstoff[data-sw="' + sw + '"]');
                            window.wsInfo( $('#wertstoff-info-cover'), $('#wertstoff-info'), wsFittingToSw );
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            }

            /**
             * don't submit
             */
            searchMuellSub.on('submit', function(e) {
                e.preventDefault();
            });

            /**
             * Shuffles array in place.
             * @param {Array} a items An array containing the items.
             */
            function shuffle(a) {
                var j, x, i;
                for (i = a.length - 1; i > 0; i--) {
                    j = Math.floor(Math.random() * (i + 1));
                    x = a[i];
                    a[i] = a[j];
                    a[j] = x;
                }
                return a;
            }

            /**
             * show n random chosen muell icons
             *
             */
            function showRandomMuellIcons() {
                var muellIconsShuffled = shuffle(muellIconsCategories);
                muellIcons.removeClass('in');

                for (var i = 1; i <= muellIconsMax; i++) {
                    searchMuellSub.find('.icon[data-category="' + muellIconsShuffled[i] + '"]:not(.in)').first().addClass('in');
                }
            }

            /**
             * show muell icons depending on result categories
             */
            function doMuellIcons () {
                var wsResults = resultList.find('.wertstoff');
                muellIcons.removeClass('in');

                wsResults.each(function () {
                    var esResult = $(this);
                    var resultSwId = esResult.attr('data-info');

                    searchMuellSub.find('.icon[data-category="' + resultSwId + '"]').addClass('in');

                    // check if there are more than max value, if so, remove some
                    var visibleIcons = searchMuellSub.find('.icon.in');
                    if (visibleIcons.length > muellIconsMax) {
                        visibleIcons = visibleIcons.slice(0, muellIconsMax);
                        searchMuellSub.find('.icon.in').not(visibleIcons).removeClass('in');
                    }
                });
            }

            /**
             * don't show any muell icon
             */
            function removeMuellIcons () {
                muellIcons.removeClass('in');
            }
        }
    });
}(jQuery));
