$(document).ready(function () {
    var feedback = $('.mod_feedback');

    if (feedback.length > 0) {
        var feedbackOpenBtn = feedback.find('.btn.js-feedback');
        var feedbackSubmitBtn = feedback.find('.btn.submit');

        /**
         * open/close feedback form
         */
        feedbackOpenBtn.click(function (e) {
            e.preventDefault();
            var feedbackBodyWrap = $(this).parent().parent().find('.feedback-body-wrap');
            feedbackBodyWrap.toggleClass('in');

            if (feedbackBodyWrap.hasClass('in')) {
                $('body, html').animate({
                    scrollTop: feedbackBodyWrap.offset().top
                }, 300);
            }
        });

        /**
         * send feedback
         */
        feedbackSubmitBtn.click(function (e) {
            e.preventDefault();
            var text1 = feedback.find('#feedback-text-1').val();
            var text2 = feedback.find('#feedback-text-2').val();

            $.ajax({
                type: 'POST',
                url: '/cms/wp-admin/admin-ajax.php',
                data: {
                    action: 'mod_feedback',
                    text_1: text1,
                    text_2: text2
                },

                success: function (data, textStatus, XMLHttpRequest) {
                    // console.log("f success");
                    resultMarkup = data.substring(0, data.length - 1);
                    // console.log(data);
                    if (resultMarkup.length > 0) {
                        feedback.find('.js-feedback-content').html(resultMarkup);
                    }
                },

                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log('Fehler in mod_feedback: ' + errorThrown);
                }
            });
        });
    }
});

