(function($) {
    $(document).ready(function () {

        /**
         * fills out Wertstoff info and opens info modal
         * param is info modal and wertstoff object
         *
         * @param wertstoffInfo
         * @param wertstoff
         */
        window.wsInfo = function (wertstoffInfoCover, wertstoffInfo, wertstoff) {
            wertstoff = $(wertstoff);

            // via these data-attribtues and corresponding css,
            // the needed fields of ws info are controlled
            wertstoffInfo.find('.icon').removeClass('in');
            wertstoffInfo.find('.icon[data-category="' + wertstoff.attr('data-info') + '"]').addClass('in');
            
            if (wertstoff.data('is-eschrott') === 1) {
                wertstoffInfo.attr('data-eschrott', 1);
            } else {
                wertstoffInfo.attr('data-eschrott', 0);
            }

            if (wertstoff.data('is-altkleider') === 1) {
                wertstoffInfo.attr('data-altkleider', 1);
            } else {
                wertstoffInfo.attr('data-altkleider', 0);
            }

            if (wertstoff.data('grundsaetzlich-kostenlos') === 1) {
                wertstoffInfo.attr('data-grundsaetzlich-kostenlos', 1);
            } else {
                wertstoffInfo.attr('data-grundsaetzlich-kostenlos', 0);
            }

            if (wertstoff.data('wsh-1') === 1) {
                wertstoffInfo.attr('data-wsh-1', 1);
            } else {
                wertstoffInfo.attr('data-wsh-1', 0);
            }

            if (wertstoff.data('wsh-0') === 1) {
                wertstoffInfo.attr('data-wsh-0', 1);
            } else {
                wertstoffInfo.attr('data-wsh-0', 0);
            }

            // wsh list
            wertstoffInfo.find('.js-wsh-list').html( wertstoff.find('.js-wsh-list').html() );

            // kosten, preis, einheit
            if (wertstoff.data('is-eschrott') === 1) {

            } else {
                // show prices only if they have values
                var preisWaage1 = $(wertstoffInfo).find('.price-waage-1');
                var preis = wertstoff.data('preis');
                if (preis) {
                    preisWaage1.removeClass('d-none');
                    wertstoffInfo.find('.wsh-list-1').html(  wertstoff.find('.js-wsh-list-1').html() );
                    wertstoffInfo.find('.js-einheit').html(wertstoff.data('einheit'));
                    wertstoffInfo.find('.js-preis').html(wertstoff.data('preis'));
                    wertstoffInfo.find('.js-mindestpreis').html(wertstoff.data('mindestpreis'));

                    if (wertstoff.data('einheit') === 'Stück') {
                        wertstoffInfo.find('.wsh-list-1').append( wertstoff.find('.js-wsh-list-0').html() );
                    }
                } else {
                    preisWaage1.addClass('d-none');
                }

                var preisWaage0 = $(wertstoffInfo).find('.price-waage-0');
                var preis100 = wertstoff.data('preis-100');

                // if theres a liter price and unit is not Stueck (those are shown in the first yellow box)
                if (preis100 && wertstoff.data('einheit') !== 'Stück') {
                    preisWaage0.removeClass('d-none');
                    wertstoffInfo.find('.wsh-list-0').html(  wertstoff.find('.js-wsh-list-0').html() );
                    wertstoffInfo.find('.js-preis-100').html(wertstoff.data('preis-100'));
                    wertstoffInfo.find('.js-preis-500').html(wertstoff.data('preis-500'));
                    wertstoffInfo.find('.js-preis-1000').html(wertstoff.data('preis-1000'));
                } else {
                    preisWaage0.addClass('d-none');
                }
            }

            wertstoffInfo.find('[data-target="search"]').html( wertstoff.data('search') );
            wertstoffInfo.find('[data-target="ws-title"]').html( wertstoff.data('ws-title') );

            // add ES id to button, find it on result button (which gets it from ws search php)
            wertstoffInfo.find('.js-es-abholung').attr('data-es-id', wertstoff.find('.js-es-abholung').data('es-id'));

            // show
            wertstoffInfoCover.addClass('in');
            wertstoffInfo.addClass('in');
        };

        /**
         * clear result list and search field on subpage Wertstoffratgeber
         */
        window.clearWsrResults = function () {
            $('#wertstoff-list').empty();
            $('#search-muell-sub').find('input').val('');
            $('#result-list').find('.result.default').addClass('in');
        };

        /**
         * show and hide AJAX loader
         */
        $(document).ajaxStart(function () {
            $("#ajax-loader").fadeIn();
        }).ajaxStop(function () {
            $("#ajax-loader").fadeOut();
        });
    });
}(jQuery));
