(function($) {
    $(document).ready(function () {
        var navItemDropdown = $('.nav-item.dropdown');
        var navMainCover = $('#nav-main-cover');

        /**
         * actions happening after click on dropdown navi
         */
        $('.nav-link.dropdown-toggle').click(function () {

            if ( !$(this).parent().hasClass('show')  ) {
                // scroll down if it's not done yet
                if ($(window).scrollTop() < 200 && $('body').hasClass('home')) {
                    $('body, html').animate({
                        scrollTop: 200
                    }, 300);
                }

                navMainCover.addClass('in');
            } else {
                navMainCover.removeClass('in');
            }
        });

        /**
         * dropdown hover, special case because of nav main dover
         */
        // navItemDropdown.hover(function () {
        //         navMainCover.addClass('in')
        // }, function () {
        //     if ($(this).find('.dropdown-menu').hasClass('show') === false) {
        //         navMainCover.removeClass('in');
        //         console.log("ja");
        //     }
        // });

        /**
         * close dropdown cover if no dropdown is open
         */
        $(document).click(function() {
            if ($('.nav-item.dropdown.show').length === 0) {
                navMainCover.removeClass('in');
            }
        });
    });
}(jQuery));