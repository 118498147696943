$(document).ready(function () {
    var searchBtn = $('#search-btn').find('a');
    var searchPage = $('#search-page');
    var searchPageCover = $('#search-page-cover');

    searchBtn.click(function (e) {
        e.preventDefault();
        searchPage.addClass('in');
        searchPageCover.addClass('in');
    });

    searchPageCover.click(function () {
        searchPage.removeClass('in');
        searchPageCover.removeClass('in');
    });

    function searchPageIn() {

    }

    function searchPageOut() {

    }
});