(function($) {
    $(document).ready(function () {
        var formSearchMuell = $('#search-muell');
        var muellInput = formSearchMuell.find('.search-field');
        var muellList = formSearchMuell.find('.search-list');
        var muellIcons = formSearchMuell.find('.icon');
        var muellIconsCategories = [];
        var muellIconsMax = 8;

        muellIcons.each(function () {
            muellIconsCategories.push( $(this).attr('data-category') );
        });

        showRandomMuellIcons();

        /**
         * muell search field gets updated (by user input)
         */
        muellInput.on('input', function () {
            if (muellInput.val().length >= 2) {
                var resultMarkup;

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'search_wertstoffe',
                        search_input: muellInput.val()
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        resultMarkup = data.substring(0, data.length - 1);

                        if (resultMarkup.length > 0) {
                            muellList.addClass('in');
                            muellList.html(resultMarkup);
                            doMuellIcons();
                        } else {
                            muellList.removeClass('in');
                            removeMuellIcons();
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            } else {
                muellList.removeClass('in');
                showRandomMuellIcons();
            }
        });

        /**
         * submit search form means not to click on specific WS
         * but send the given input to search_sub page
         */
        formSearchMuell.on('submit', function(e) {
            e.preventDefault();

            $.ajax({
                type: 'POST',
                url: '/cms/wp-admin/admin-ajax.php',
                data: {
                    action: 'delete_sw'
                },

                success: function (data, textStatus, XMLHttpRequest) {
                    // TODO make link dynamic
                    window.location.href = 'entsorgung/wertstoffratgeber/?i=' + muellInput.val();
                },

                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log('errör ' + errorThrown);
                }
            });

        });

        // TODO needed?
        $('#wertstoffe-info').height($(document).height() + 64);

        /**
         * click on link in search field to set sw to session
         * and continue to search_sub page
         */
        $(document).on('click', '.js-ws-search-home' , function(e) {
            e.preventDefault();

            var thisLink = $(this);
            var sw = thisLink.attr('data-sw');

            $.ajax({
                type: 'POST',
                url: '/cms/wp-admin/admin-ajax.php',
                data: {
                    action: 'set_sw',
                    sw: sw
                },

                success: function (data, textStatus, XMLHttpRequest) {
                    window.location = thisLink.attr('href');
                },

                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log('errör ' + errorThrown);
                }
            });
        });

        /**
         * Shuffles array in place.
         * @param {Array} a items An array containing the items.
         */
        function shuffle(a) {
            var j, x, i;
            for (i = a.length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1));
                x = a[i];
                a[i] = a[j];
                a[j] = x;
            }
            return a;
        }

        /**
         * show n random chosen muell icons
         *
         */
        function showRandomMuellIcons() {
            var muellIconsShuffled = shuffle(muellIconsCategories);
            muellIcons.removeClass('in');

            for (var i = 1; i <= muellIconsMax; i++) {
                formSearchMuell.find('.icon[data-category="' + muellIconsShuffled[i] + '"]:not(.in)').first().addClass('in');
            }
        }

        /**
         * show muell icons depending on result categories
         */
        function doMuellIcons () {
            var resultFDat = muellList.find('.js-ws-search-home');
            muellIcons.removeClass('in');

            resultFDat.each(function () {
                var link = $(this);
                var resultSwId = link.attr('data-ws');

                formSearchMuell.find('.icon[data-category="' + resultSwId + '"]').addClass('in');

                // check if there are more than max value, if so, remove some
                var visibleIcons = formSearchMuell.find('.icon.in');
                if (visibleIcons.length > muellIconsMax) {
                    visibleIcons = visibleIcons.slice(0, muellIconsMax);
                    formSearchMuell.find('.icon.in').not(visibleIcons).removeClass('in');
                }
            });
        }

        function removeMuellIcons () {
            muellIcons.removeClass('in');
        }

    });
}(jQuery));
