(function($) {
    $(document).ready(function () {
        var templateEsUserdata = $('.js-template-eschrott-userdata');

        if (templateEsUserdata.length > 0) {
            var formUserdataEdit = $('#form-es-userdata-edit');
            var formUserdataDelete = $('#form-es-userdata-delete');
            var userEditFirstName = $('#es-userdata-firstname');
            var userEditLastName = $('#es-userdata-lastname');
            var userEditEmail = $('#es-userdata-email');
            var userEditPhone = $('#es-userdata-phone');
            var userEditPw1 = $('#es-userdata-password-new-1');
            var userEditPw2 = $('#es-userdata-password-new-2');

            /**
             * enable submit button on first change of user data
             */
            $('#es-userdata-firstname, #es-userdata-lastname, #es-userdata-email, #es-userdata-phone, ' +
                '#es-userdata-password-new-1, #es-userdata-password-new-2').on('change paste keyup', function() {
                formUserdataEdit.find('button').prop('disabled', false);
            });

            /**
             * form userdata edit
             */
            formUserdataEdit.submit(function(e) {
                e.preventDefault();
                var firstName = userEditFirstName.val();
                var lastName = userEditLastName.val();
                var email = userEditEmail.val();
                var phone = userEditPhone.val();
                var errorDefault = templateEsUserdata.find('.error.default');
                var errorCustom = templateEsUserdata.find('.error.custom');
                var messageSuccess = templateEsUserdata.find('.message.success');
                var data = {
                    action: 'update_user',
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                };

                if (userEditPw1.val().length > 0 && userEditPw2.val().length > 0) {
                    data.pw_new_1 = userEditPw1.val();
                    data.pw_new_2 = userEditPw2.val();
                }

                errorDefault.removeClass('in');
                messageSuccess.removeClass('in');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: data,

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        var result = JSON.parse(data);

                        if (result.success) {
                            window.location.reload();
                        } else if (result.error) {
                            errorCustom.addClass('in');
                            errorCustom.html(result.message);
                            messageSuccess.removeClass('in');
                            errorDefault.removeClass('in');
                        } else {
                            errorDefault.addClass('in');
                            messageSuccess.removeClass('in');
                            errorCustom.removeClass('in');
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * delete a user_pickup
             */
            $(document).on('click', '.js-delete-user-pickup' , function(e) {
                e.preventDefault();
                // var pickupDate = sendPickupDate.find('.form-check-input:checked').val();
                // var errorPickupDate = sendPickupDate.find('.error');
                // var pickupDateSuccessCustom = sendPickupDate.find('.message.success.custom');
                // var pickupDateErrorCustom = sendPickupDate.find('.message.error.custom');
                var userPickupId = $(this).closest('.user-pickup').attr('data-pickup-id');

                if (userPickupId) {
                    // errorPickupDate.removeClass('in');
                    // pickupDateSuccessCustom.removeClass('in');

                    $.ajax({
                        type: 'POST',
                        url: '/cms/wp-admin/admin-ajax.php',
                        data: {
                            action: 'delete_user_pickup',
                            user_pickup: userPickupId
                        },

                        success: function (data, textStatus, XMLHttpRequest) {
                            var result = data.substring(0, data.length - 1);
                            $('#js-es-user-pickups').html($(result).html());

                            // else if (result.error) {
                            //     // pickupDateErrorCustom.html(result.message);
                            //     // pickupDateErrorCustom.addClass('in');
                            // } else {
                            //     // pickupDateErrorCustom.html(result);
                            //     // pickupDateErrorCustom.addClass('in');
                            // }
                        },

                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            console.log('errör ' + errorThrown);
                        }
                    });
                } else {
                    // errorPickupDate.addClass('in');
                }
            });

            formUserdataDelete.submit(function(e) {
                e.preventDefault();
                var message = $(this).find('#es-userdata-delete-message').val();
                var errorDefault = templateEsUserdata.find('.error.default');
                var messageSuccess = templateEsUserdata.find('.message.success');

                errorDefault.removeClass('in');
                messageSuccess.removeClass('in');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'es_userdata_delete',
                        message: message,
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        window.location.reload();
                        // data = data.substring(0, data.length - 1);
                        // var result = JSON.parse(data);
                        //
                        // if (result.success) {
                        //     window.location.reload();
                        // } else if (result.error) {
                        //     errorDefault.addClass('in');
                        //     messageSuccess.removeClass('in');
                        // }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });
        }

        $(document).on('click', '.js-get-pdf-confirmation' , function(e) {
            e.preventDefault();

            var thisBtn = $(this);
            var pdfSuccessMessage = thisBtn.parent().find('.message.success.js-pdf-confirmation-message');
            var pdfErrorMessage = thisBtn.parent().find('.message.error.js-pdf-confirmation-message');
            var userPickup = thisBtn.closest('.user-pickup').attr('data-pickup-id');

            $.ajax({
                type: 'POST',
                url: '/cms/wp-admin/admin-ajax.php',
                data: {
                    action: 'get_pdf_confirmation',
                    user_pickup: userPickup
                },

                success: function (data, textStatus, XMLHttpRequest) {
                    data = data.substring(0, data.length - 1);
                    var result = JSON.parse(data);

                    if (result.success) {
                        window.open(result.link, '_blank');
                        pdfSuccessMessage.html('<a href="' + result.link + '" target="_blank" class="d-block mt-4">Download Auftragsbestätigung (PDF)</a>');
                        pdfSuccessMessage.addClass('in');
                    } else {
                        pdfErrorMessage.html(result.message);
                        pdfErrorMessage.addClass('in');
                    }
                },

                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log('errör ' + errorThrown);
                }
            });
        });


    });
}(jQuery));
