(function($) {
    $(document).ready(function () {
        if (('.js-template-eschrott').length > 0 ) {
            var esConfirm = $('#form-es-confirm');

            /**
             * form login
             */
            esConfirm.submit(function (e) {
                e.preventDefault();
                var code = $('#confirm-code').val();
                var confirmErrorCustom = esConfirm.find('.error.custom');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'es_confirm',
                        code: code
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        var result = $.parseJSON(data);
                        if (result.success) {
                            window.location = result.url;
                        } else if (result.error) {
                            confirmErrorCustom.html(result.message);
                            confirmErrorCustom.addClass('in');
                        } else {
                            confirmErrorCustom.html(result);
                            confirmErrorCustom.addClass('in');
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });
        }
    });
}(jQuery));
