(function($) {
    $(document).ready(function () {
        if (('.js-template-eschrott').length > 0 ) {

            $.validate({
                form : '.form-validator',
                lang: 'de',
                errorElementClass: 'validation-error',
                modules : 'security',
            });

            var esList = $('#es-list');
            var esSearchField = $('#es-search').find('.search-field');
            var esListItems = esList.find('.es-list-item');
            var esListNoResults = esList.find('.no-results');

            /**
             * add device to pickup_list
             */
            $(document).on('click', '.js-es-abholung' , function(e) {
                e.preventDefault();
                var href = this.href;

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'add_es',
                        es_id: $(this).data('es-id')
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        // var result = $.parseJSON(data);
                        window.location = href;
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * search in device list
             */
            esSearchField.on('input', function () {
                if (esSearchField.val().length >= 1) {
                    esListItems.each(function () {
                        var thisItem = $(this);
                        if (thisItem.data('es-name').toLowerCase().indexOf(esSearchField.val().toLowerCase()) !== -1) {
                            thisItem.removeClass('out')
                        } else {
                            thisItem.addClass('out')
                        }
                    });
                } else {
                    esListItems.removeClass('out');
                }

                // no rsults message
                if (esList.find('.es-list-item:not(.out)').length < 1) {
                    esListNoResults.addClass('in');
                } else {
                    esListNoResults.removeClass('in');
                }
            });
        }
    });
}(jQuery));
