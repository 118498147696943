(function($) {
    $(document).ready(function () {
        if (('.js-template-eschrott').length > 0 ) {
            // var formUserdataPassword = $('#form-es-userdata-password');
            var sendResetPasswordEmail = $('#form-es-send-reset-password-email');
            var formRestPasswordMailLinkTarget = $('#form-es-userdata-password-link-target');

            /**
             * form userdata password
             */
            // formUserdataPassword.submit(function(e) {
            //     e.preventDefault();
            //     var passwordNew1 = $('#es-userdata-password-new-1').val();
            //     var passwordNew2 = $('#es-userdata-password-new-2').val();
            //
            //     var errorDefault = formUserdataPassword.find('.custom-error');
            //     var messageSuccess = formUserdataPassword.find('.success');
            //
            //     errorDefault.removeClass('in');
            //     messageSuccess.removeClass('in');
            //
            //     $.ajax({
            //         type: 'POST',
            //         url: '/cms/wp-admin/admin-ajax.php',
            //         data: {
            //             action: 'es_reset_password',
            //             pw_new_1: passwordNew1,
            //             pw_new_2: passwordNew2,
            //         },
            //
            //         success: function (data, textStatus, XMLHttpRequest) {
            //             data = data.substring(0, data.length - 1);
            //             var result = $.parseJSON(data);
            //             if (result.success) {
            //                 errorDefault.removeClass('in');
            //                 messageSuccess.html(result.message);
            //                 messageSuccess.addClass('in');
            //                 formUserdataPassword.find('input').val('');
            //             } else {
            //                 console.log(result.message);
            //                 errorDefault.html(result.message);
            //                 errorDefault.addClass('in');
            //                 messageSuccess.removeClass('in');
            //             }
            //         },
            //
            //         error: function (XMLHttpRequest, textStatus, errorThrown) {
            //             console.log('errör ' + errorThrown);
            //             errorDefault.addClass('in');
            //             errorDefault.removeClass('in');
            //             messageSuccess.removeClass('in');
            //         }
            //     });
            // });

            /**
             * click
             */
            sendResetPasswordEmail.submit(function (e) {
                e.preventDefault();
                var email = $('#password-reset-email').val();
                var passwordEmailSuccessCustom = sendResetPasswordEmail.find('.success.custom');
                var passwordEmailErrorCustom = sendResetPasswordEmail.find('.custom-error');

                if (email) {
                    passwordEmailSuccessCustom.removeClass('in');
                    passwordEmailErrorCustom.removeClass('in');

                    $.ajax({
                        type: 'POST',
                        url: '/cms/wp-admin/admin-ajax.php',
                        data: {
                            action: 'send_reset_password_email',
                            email: email
                        },

                        success: function (data, textStatus, XMLHttpRequest) {
                            data = data.substring(0, data.length - 1);
                            var result = $.parseJSON(data);
                            if (result.success) {
                                passwordEmailSuccessCustom.html(result.message);
                                passwordEmailSuccessCustom.addClass('in');
                                passwordEmailErrorCustom.removeClass('in');
                                sendResetPasswordEmail.find('input').val('');
                            } else if (result.error) {
                                passwordEmailErrorCustom.html(result.message);
                                passwordEmailErrorCustom.addClass('in');
                                passwordEmailSuccessCustom.removeClass('in');
                            } else {
                                passwordEmailErrorCustom.html(result);
                                passwordEmailErrorCustom.addClass('in');
                                passwordEmailSuccessCustom.removeClass('in');
                            }
                        },

                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            console.log('errör ' + errorThrown);
                        }
                    });
                }
            });

            /**
             * reset password on form which was reached via email link
             */
            formRestPasswordMailLinkTarget.submit(function(e) {
                e.preventDefault();
                var passwordNew1 = $('#es-userdata-password-new-1').val();
                var passwordNew2 = $('#es-userdata-password-new-2').val();
                var token = null;
                var url_string = window.location.href;
                var url = new URL(url_string);
                var paramToken = url.searchParams.get('token');

                if (paramToken && paramToken.length > 0) {
                    token = paramToken;
                }

                var errorDefault = formRestPasswordMailLinkTarget.find('.custom-error');
                var messageSuccess = formRestPasswordMailLinkTarget.find('.success');

                errorDefault.removeClass('in');
                messageSuccess.removeClass('in');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'es_reset_password_linktarget',
                        token: token,
                        pw_new_1: passwordNew1,
                        pw_new_2: passwordNew2,
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        // console.log(data);
                        var result = $.parseJSON(data);
                        if (result.success) {
                            errorDefault.removeClass('in');
                            messageSuccess.html(result.message);
                            messageSuccess.addClass('in');
                            formRestPasswordMailLinkTarget.find('input').val('');
                        } else {
                            // console.log(result.message);
                            errorDefault.html(result.message);
                            errorDefault.addClass('in');
                            messageSuccess.removeClass('in');
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                        errorDefault.addClass('in');
                        errorDefault.removeClass('in');
                        messageSuccess.removeClass('in');
                    }
                });
            });
        }
    });
}(jQuery));
