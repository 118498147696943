(function($) {
    $(document).ready(function () {
        if (('.js-template-eschrott').length > 0 ) {
            var esList = $('#es-list');
            var clearPickupList = $('#js-clear-pickup-list');
            var removeFromPickupList = $('.js-pickup-list-remove');
            var add = esList.find('.js-add-to-pickup-list');
            var formEsComment = $('#form-es-comment');

            setTimeout(function() {
                putMobilePickupListDown();
            }, 2000);

            /**
             * open or close pickupList (mobile) on click
             */
            $(document).on('click', '#pickup-list .js-openclose' , function(e) {
                var pickupList = $('#pickup-list');
                pickupList.toggleClass('mobile-in');

                if (pickupList.hasClass('mobile-in')) {
                    putMobilePickupListUp();
                } else {
                    putMobilePickupListDown();
                }
            });

            /**
             * re-adjust mobile pickup list on 'out' position
             */
            function reAdjustMobilePickupList() {
                var pickupList = $('#pickup-list');
                var pickupTransition = pickupList.css('transition');
                pickupList.css('transition', 'none');
                putMobilePickupListDown();

                setTimeout(function() {
                    pickupList.css('transition', pickupTransition);
                    pickupList.css('bottom', pickupList.find('.header').outerHeight() - pickupList.outerHeight() + 30);

                    setTimeout(function() {
                        putMobilePickupListDown();
                    }, 300);
                }, 100);
            }

            /**
             * click on "+" to add to pickup list
             */
            add.click(function() {
                var thisAdd = $(this);

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'add_es',
                        es_id: thisAdd.parent().data('es-id'),
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        var pickupList = $('#pickup-list');
                        var resultMarkup = data.substring(0, data.length - 1);
                        pickupList.html($(resultMarkup).html());
                        reAdjustMobilePickupList();
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * clear pickupList
             */
            $(document).on('click', '#js-clear-pickup-list' , function(e) {
                e.preventDefault();

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'clear_pickup_list'
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        window.location.reload();
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * click in "x" to remove an entry from pickup list
             */
            $(document).on('click', '.js-pickup-list-remove' , function(e) {
                e.preventDefault();
                var thisItem = $(this);

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'remove_from_pickup_list',
                        es_id: thisItem.parent().attr('data-es-id')
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        var pickupList = $('#pickup-list');
                        var resultMarkup = data.substring(0, data.length - 1);
                        // console.log($(resultMarkup).html());

                        pickupList.html($(resultMarkup).html());
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * show mobile pickup list
             */
            function putMobilePickupListUp() {
                var pickupList = $('#pickup-list');
                pickupList.css('bottom', 0);
            }

            /**
             * hide mobile pickup list
             */
            function putMobilePickupListDown() {
                var pickupList = $('#pickup-list');
                var bottomVal = pickupList.find('.header').outerHeight() - pickupList.outerHeight();
                pickupList.css('bottom', bottomVal);
            }

            // var sticky = false;
            // $(window).scroll(function () {
            //     // console.log($(window).scrollTop());
            //     // console.log($('#pickup-list').offset().top);
            //     if ( $(window).scrollTop() > $('#pickup-list').offset().top - 200 ) {
            //         if (! sticky) {
            //             $('#pickup-list').addClass('desktop-sticky');
            //             sticky = true;
            //         }
            //     } else {
            //         $('#pickup-list').removeClass('desktop-sticky');
            //         sticky = false;
            //     }
            // });

            formEsComment.submit(function (e) {
                e.preventDefault();
                var comment = $('#es-comment').val();
                // var formEsComment = esLogin.find('.custom-error');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'es_set_comment',
                        comment: comment,
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        var pickupList = $('#pickup-list');
                        var resultMarkup = data.substring(0, data.length - 1);
                        pickupList.html($(resultMarkup).html());
                        reAdjustMobilePickupList();
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });
        }
    });
}(jQuery));