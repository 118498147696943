(function($) {
    $(document).ready(function () {
        if (('.js-template-eschrott').length > 0 ) {

            /**
             * send pickup date
             */
            $(document).on('click', '#form-send-pickup-date .btn' , function(e) {
                e.preventDefault();
                var sendPickupDate = $('#form-send-pickup-date');
                var pickupDateRadio = sendPickupDate.find('.form-check-input');
                var pickupDate = sendPickupDate.find('.js-check-pickup-date:checked').val();
                var errorPickupDate = sendPickupDate.find('.message.error.mandatory');
                var pickupDateSuccessCustom = sendPickupDate.find('.message.success.custom');
                var pickupDateErrorCustom = sendPickupDate.find('.message.error.custom');
                var propertyAccess = sendPickupDate.find('.pickup-property-access:checked').val();

                if (pickupDate) {
                    errorPickupDate.removeClass('in');
                    pickupDateSuccessCustom.removeClass('in');

                    $.ajax({
                        type: 'POST',
                        url: '/cms/wp-admin/admin-ajax.php',
                        data: {
                            action: 'es_send_pickupdate',
                            pickup_date: pickupDate,
                            property_access: propertyAccess,
                            address_id: sendPickupDate.find('#pickup-address-id').val()
                        },

                        success: function (data, textStatus, XMLHttpRequest) {
                            data = data.substring(0, data.length - 1);
                            var result = $.parseJSON(data);

                            if (result.success) {
                                pickupDateRadio.prop('checked', false);
                                if (result.url) {
                                    window.location = result.url;
                                } else if (result.message) {
                                    pickupDateSuccessCustom.html(result.message);
                                    pickupDateSuccessCustom.addClass('in');
                                }
                            } else if (result.error) {
                                pickupDateErrorCustom.html(result.message);
                                pickupDateErrorCustom.addClass('in');
                            } else {
                                pickupDateErrorCustom.html(result);
                                pickupDateErrorCustom.addClass('in');
                            }
                        },

                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            console.log('errör ' + errorThrown);
                        }
                    });
                } else {
                    errorPickupDate.addClass('in');
                }
            });

            /**
             *
             */
            $('#form-es-get-pickup-date-by-address').submit(function (e) {
                e.preventDefault();
                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'get_pickup_date_by_address',
                        address_id: $('#address-for-pickup-date option:selected').data('id'),
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        $('#js-pickup-form-wrap').html(data);
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });
        }
    });
}(jQuery));