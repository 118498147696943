(function($) {
    $(document).ready(function () {
        var wertstoffInfoCover = $('#wertstoff-info-cover');
        var wertstoffInfo = $('#wertstoff-info');

        /**
         * click on Wertstoff fills Wertstoff Info and shows it,
         * but sets clicked sw first to session so that it can
         * be reopened after potential page reload
         */
        $(document).on('click', '.wertstoff .left' , function() {
            var thisWsLeft = $(this);
            var thisWs = thisWsLeft.parent();
            var sw = thisWs.data('sw');

            $.ajax({
                type: 'POST',
                url: '/cms/wp-admin/admin-ajax.php',
                data: {
                    action: 'set_sw',
                    sw: sw
                },

                success: function (data, textStatus, XMLHttpRequest) {
                    window.wsInfo( wertstoffInfoCover, wertstoffInfo, thisWs );
                },

                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log('errör ' + errorThrown);
                }
            });
        });

        /**
         * closes Wertstoff Info
         */
        wertstoffInfo.find('.close').click(function() {
            closeWsInfo();
        });

        /**
         * click on cover under window
         */
        wertstoffInfoCover.click(function(){
            closeWsInfo();
        });

        /**
         * close ws info window and execute additional tasks
         */
        function closeWsInfo() {
            wertstoffInfoCover.removeClass('in');
            wertstoffInfo.removeClass('in');

            $.ajax({
                type: 'POST',
                url: '/cms/wp-admin/admin-ajax.php',
                data: {
                    action: 'delete_sw'
                },

                // success: function (data, textStatus, XMLHttpRequest) {
                // },

                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log('errör ' + errorThrown);
                }
            });
        }
    });
}(jQuery));
