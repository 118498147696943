(function($) {
    $(document).ready(function () {
        var templateAdd = $('.js-template-eschrott-add-address');
        var templateDelete = $('.js-template-eschrott-userdata');

        // template add new address
        if (templateAdd.length > 0) {
            var esAddAddress = $('#form-es-add-address');

            /**
             * add new addres
             */
            esAddAddress.submit(function (e) {
                e.preventDefault();
                var streetId = $('#register-street').val();
                var addressNumber = $('#register-addressno').val();
                var addAddressErrorCustom = esAddAddress.find('.message.custom-error');
                var addAddressSuccess = esAddAddress.find('.message.success');

                addAddressSuccess.removeClass('in');
                addAddressErrorCustom.removeClass('in');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'create_address',
                        street_id: streetId,
                        address_number: addressNumber
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        var result = $.parseJSON(data);

                        if (result.success) {
                            $('#register-city').val('');
                            $('#register-street').val('');
                            $('#register-addressno').val('');

                            if (result.url) {
                                window.location = result.url;
                            } else if (result.message) {
                                addAddressSuccess.html(result.message);
                                addAddressSuccess.addClass('in');
                            }
                            console.log(result);
                        } else if (result.error) {
                            addAddressErrorCustom.html(result.message);
                            addAddressErrorCustom.addClass('in');
                        } else {
                            addAddressErrorCustom.html(data);
                            addAddressErrorCustom.addClass('in');
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });
        }

        // template: delete address
        if (templateDelete.length > 0) {

            /**
             * delete clicked address
             */
            $(document).on('click', '.js-delete-address' , function(e) {
                e.preventDefault();
                var thisBtn = $(this);
                // var deleteAddressError = thisBtn.parent().find('.custom-error');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'delete_address',
                        address_id: thisBtn.attr('data-address-id'),
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        $('#es-user-addresses').html($(data).html());
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });
        }
    });
}(jQuery));