(function($) {
    $(document).ready(function () {
        if (('.js-template-eschrott').length > 0 ) {
            var btnLogout = $('.js-es-logout');
            var esLogin = $('#form-es-login');
            var esRegister = $('#form-es-register');
            var selectCity = $('#register-city');
            var selectStreet = $('#register-street');

            /**
             * logout
             */
            btnLogout.click(function (e) {
                e.preventDefault();

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'es_logout',
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        console.log(data);
                        var result = $.parseJSON(data);
                        if (result.error) {
                            console.log(result.error);
                            $('.error.custom').append(result.error);
                        } else if (result.url) {
                            window.location = result.url;
                        } else {
                            console.log("?");
                        }
                        // window.location = result.url;
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * form login
             */
            esLogin.submit(function (e) {
                e.preventDefault();
                var customerNo = $('#login-customerno').val();
                var password = $('#login-password').val();
                var loginErrorCustom = esLogin.find('.custom-error');

                loginErrorCustom.removeClass('in');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'es_login',
                        customer_no: customerNo,
                        password: password
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        var result = $.parseJSON(data);
                        console.log(data);

                        console.log(result.error);
                        if (result.success) {
                            window.location = result.url;
                        } else {
                            loginErrorCustom.html(result.message);
                            loginErrorCustom.addClass('in');
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * register
             */
            esRegister.submit(function (e) {
                e.preventDefault();
                var email = $('#register-email').val();
                var password = $('#register-password').val();
                var firstName = $('#register-firstname').val();
                var lastName = $('#register-lastname').val();
                var phone = $('#register-phone').val();
                var city = $('#register-city').val();
                var streetId = $('#register-street').val();
                var addressNumber = $('#register-addressno').val();
                var acceptedPrivacyPolicy = $('#register-accepted-privacy-policy').prop('checked');
                var registerErrorCustom = esRegister.find('.custom-error');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'es_register',
                        email: email,
                        password: password,
                        firstname: firstName,
                        lastname: lastName,
                        phone: phone,
                        city: city,
                        street_id: streetId,
                        address_number: addressNumber,
                        accepted_privacy_policy: acceptedPrivacyPolicy
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        var result = $.parseJSON(data);

                        if (result.success) {
                            window.location = result.url;
                        } else if (result.error) {
                            registerErrorCustom.html(result.message);
                            registerErrorCustom.addClass('in');
                        } else {
                            registerErrorCustom.html(data);
                            registerErrorCustom.addClass('in');
                        }
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });

            /**
             * city select field changes value
             */
            selectCity.change(function () {
                selectStreet.addClass('out');

                $.ajax({
                    type: 'POST',
                    url: '/cms/wp-admin/admin-ajax.php',
                    data: {
                        action: 'get_streets',
                        city_id: selectCity.find('option:selected').attr('data-id')
                    },

                    success: function (data, textStatus, XMLHttpRequest) {
                        data = data.substring(0, data.length - 1);
                        data = $.parseJSON(data);
                        var markup = '<option value="" selected>Bitte Straße wählen</option>';

                        for (var i = 0; i < data.length; i++) {
                            markup += '<option value="' + data[i]['id'] + '">' + data[i]['name'] + '</option>';
                            selectStreet.html(markup);
                        }

                        selectStreet.removeClass('out');
                    },

                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('errör ' + errorThrown);
                    }
                });
            });
        }
    });
}(jQuery));
